<template>
  <div>
    <!-- ... Existing code ... -->
    <!-- 添加内容表单 -->
    <div class="clear"></div>
    <div class="denglu" style="margin-top: 30px;">
      <!-- 使用 v-if 来控制表单的显示与隐藏 -->
      <form v-if="showForm" @submit.prevent="handleSubmitt" class="form">
        <div class="form-group">
          <label for="url" class="label">输入内容:</label>
          <!-- 修改 v-model 绑定，将内容存储到 formData.url 数组中 -->
          <input v-model="newUrl" type="text" id="url" class="input">
          <!-- 修改：将添加内容按钮改为普通按钮，并绑定点击事件 addUrl -->
          <button type="button" @click.prevent="addUrl" class="add-button">添加内容</button>
        </div>
        <div v-if="formData.url.length" class="url-list">
          <!-- 移除发送按钮 -->
          <ul class="url-list-items">
            <li v-for="(urln, index) in formData.url" :key="index" class="url-list-item">{{ urln }}</li>
          </ul>
        </div>
        <!-- 修改：添加提交按钮 -->
        <button type="submit" class="submit-button">发送</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      // ... Existing data ...
      newUrl: '', // 用于暂存表单输入的内容
      // ... Existing data ...
    };
  },

  methods: {
    // ... Existing methods ...
    // 添加内容按钮点击处理
    addUrl() {
      if (this.newUrl.trim()) {
        this.formData.url.push(this.newUrl.trim());
        this.newUrl = ''; // 清空输入框
      }
    },

    // 提交添加内容的表单处理
    handleSubmitt() {
      if (this.username && this.selectedDevice && this.formData.url.length > 0) {
        this.formData.user = this.username;
        this.formData.mobile = this.selectedDevice;

        axios
          .post("https://ttlife.lucky67.top/auto/auto/insert", this.formData)
          .then((response) => {
            console.log("提交成功", response.data);
            this.formData.url = []; // 清空添加内容数组
            this.newUrl = ''; // 清空输入框
            window.location.reload();
          })
          .catch((error) => {
            console.error("提交失败", error);
          });
      } else {
        console.error("用户名、设备和内容不能为空");
      }
    },
    // ... Existing methods ...
  },
};
</script>
