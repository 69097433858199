import Vue from 'vue';
import VueRouter from 'vue-router';


Vue.use(VueRouter);

import SuccessComponent from '../views/SuccessComponent.vue'; // 导入成功组件的路径
import UserSearch from '../views/UserSearch.vue'; // 导入UserSearch组件的路径

const routes = [
  {
    path: '../views/successcomponent', // URL路径
    component: SuccessComponent, // 对应的Vue组件
    name: 'Successcomponent', // 可选，路由名称
  },
  {
    path: '../views/UserSearch',
    component: UserSearch,
    name: 'UserSearch',
  },
  // 可以添加更多路由
];

const router = new VueRouter({
  routes,
});

export default router;
