<template>
  <div>
    <div class="denglu" style="margin-top: 20px;">
      <form @submit.prevent="handleSubmit" class="form">
        <div class="form-group">
          <label for="user" class="label">用户名称:</label>
          <input v-model="username" type="text" id="user" required class="input" style="margin-right: 10ox;" />
          <button type="submit" class="add-button" ref="loginButton">点击登录</button>
        </div>
      </form>
    </div>

    <div v-if="userData.length">
      <div class="denglu">
        <p><font color="red">{{ username }}</font>已登录，当前日期：{{ currentDate }}</p>
      </div>
      <div v-for="device in devices" :key="device">
        <div class="shebei">
          <li  @click="selectDevice(device)"  :style="{ color: selectedDevice === device ? 'red' : 'black', fontWeight: selectedDevice === device ? 'bold' : 'normal' }" >
            <div class="shebei_biaoti">
              <button  :style="{ color: selectedDevice === device ? 'red' : 'black', fontWeight: selectedDevice === device ? 'bold' : 'normal' }"> {{ device }}   </button>
            </div>
            <div v-for="(userItem, index) in sortedUserData" :key="index" class="shebei_tongji">
              <template v-if="userItem.mobile === device">
                <!--{{ userItem.mobile }} --> 总:{{ userItem.count }} <br />剩:{{ userItem.residue }}
              </template>
            </div>
          </li>
        </div>
      </div>
    </div>

    <div v-else class="denglu">
      <p v-if="username.trim() || shebei.trim()">未找到用户数据</p>
    </div>

    <!-- 添加内容表单 -->
    <div class="clear"></div>
    <div class="denglu" style="margin-top: 30px;">
      <!-- 使用 v-if 来控制表单的显示与隐藏 -->
      <form v-if="showForm" @submit.prevent="handleSubmitt" class="form">
        <div class="form-group">
          <label for="url" class="label">输入内容:</label>
          <!-- 修改 v-model 绑定，将内容存储到 formData.url 数组中 -->
          <input v-model="newUrl" type="text" id="url" class="input">
          <!-- 直接发送内容，并删除多余的代码 -->
          <button type="submit" class="add-button">添加</button>
        </div>
        <div v-if="formData.url.length" class="url-list">
          <ul class="url-list-items">
            <li v-for="(urln, index) in formData.url" :key="index" class="url-list-item">{{ urln }}</li>
          </ul>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      username: localStorage.getItem("username") || "",
      shebei: "",
      count: "",
      residue: "",
      userData: [],
      formData: {
        user: "",
        mobile: "",
        url: [],
      },
      newUrl: "", // 用于暂存表单输入的内容
      showForm: false, // 控制添加内容表单的显示与隐藏
      selectedDevice: null, // 选择的设备（初始为null）
      currentDate: "", // 用于显示当前日期

      devices: [
        // 存储设备选项的数组
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
    };
  },

  computed: {
    // 计算属性返回按电话号码升序排列的用户数据
    sortedUserData() {
      return this.userData.slice().sort((a, b) => a.mobile.localeCompare(b.mobile));
    },
  },
  methods: {

   // 处理重新登录逻辑
   handleReLogin() {
      // 清空formData.url数组中的内容
      this.formData.url = [];
      // 清空输入框中的内容
      this.newUrl = "";
    },


    handleSubmit() {
      this.formData.url = [];
      if (this.username.trim() || this.shebei.trim()) {
        axios
          .post("https://ttlife.lucky67.top/auto/auto/get_data", {
            user: this.username,
            mobile: this.shebei,
          })
          .then((response) => {
            if (response.data.code === 200) {
              console.log("登录成功", response.data);
              localStorage.setItem("username", this.username);
              this.processUserData(response.data.data);

              // 在登录成功后，模拟点击添加按钮
              if (this.newUrl.trim()) {
                this.handleSubmitt();
              }
            } else {
              console.error("登录失败，请用自己的用户名", response.data);
              this.userData = [];
            }
          })
          .catch((error) => {
            console.error("登录失败，请用自己的用户名", error);
            this.userData = [];
          });
      } else {
        this.userData = [];
      }
      this.showForm = true;
    },

    // 提交添加内容的表单处理
    handleSubmitt() {
      if (this.username && this.selectedDevice && this.newUrl.trim()) {
        this.formData.user = this.username;
        this.formData.mobile = this.selectedDevice;
        // 添加新的内容到 formData.url 数组中
        this.formData.url.push(this.newUrl.trim());

        axios
          .post("https://ttlife.lucky67.top/auto/auto/insert", this.formData)
          .then((response) => {
            console.log("提交成功", response.data);
            // 发送完成后，清空输入框内容，并刷新页面
            this.newUrl = "";
            this.showForm = false; // Hide the form after successful submission

            // Use a setTimeout to delay the click action
            setTimeout(() => {
              // Programmatically click the login button
              this.$refs.loginButton.click();
            }, 1000);
          })
          .catch((error) => {
            console.error("提交失败", error);
          });
      } else {
        console.error("用户名、设备和内容不能为空");
      }
      this.showForm = true;
    },

    // 处理用户数据
    processUserData(userData) {
      const users = {};
      userData.forEach((item) => {
        const key = item.username + item.mobile;
        if (!users[key]) {
          users[key] = {
            username: item.username,
            mobile: item.mobile,
            count: item.count,
            residue: item.residue,
            urls: [], // 存储所有URL
            activeUrls: [], // 存储未删除的URL
          };
        }

        // 将URL添加到对应数组
        users[key].urls.push(item.url);
        if (item.is_del === 0) {
          users[key].activeUrls.push(item.url);
        }
      });

      this.userData = Object.values(users);
    },

    // 计算未删除的URL数量
    activeUrlCount(userItem) {
      return userItem.activeUrls.length;
    },

    // 选择设备处理
    selectDevice(device) {
      this.selectedDevice = device;
    },

    
  },

  mounted() {
    this.username = localStorage.getItem("username") || "";
    // 使用 setInterval 更新当前日期
    setInterval(() => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const date = String(now.getDate()).padStart(2, "0");
      this.currentDate = `${year}-${month}-${date}`;
    }, 1000); // 每隔一秒钟更新一次日期

    // 自动登录逻辑（若已有用户名和设备，并且有输入内容，则尝试自动登录）
    if (this.username.trim() && this.shebei.trim() && this.newUrl.trim()) {
      this.handleSubmit();
      this.handleReLogin();
    }
  },
};
</script>
