<template>
  <div>
   
   
    <UserSearch></UserSearch>
   <!--<SuccessComponent></SuccessComponent>-->
    
 
  </div>
</template>


<script>
 

import UserSearch from './views/UserSearch.vue';
//import SuccessComponent from './views/SuccessComponent.vue';

export default {
  components: {
   
    UserSearch,
 //   SuccessComponent,
  },
};
</script>



